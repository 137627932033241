export const baseURL = `https://qa.api.networksmarketer.com`
// export const baseURL = `http://localhost:49999`
export const baseWS = `wss://qa.api.networksmarketer.com`
// export const baseWS = `ws://localhost:49999`

export const baseWeb = `https://qa.networksmarketer.com`

export const productImageBase = 'https://networksmarketer.com/images/'

export function handleErrors(response) {
    if (response.status === 401 || response.status === 404 || response.status === 503){
     throw Error(response.status)
    }
    if (!response.ok) {
      throw response.json()
    }
    if (response.status === 204){
      return {}
    }
    return response.json()
}